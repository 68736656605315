'use client';

import Image from 'next/image';
import Close from '@/assets/icons/login&signup/close.svg?url';
import AppIcon from '@/assets/icons/login&signup/gobuid-app.svg?url';
import { useState } from 'react';
import useCheckMobileAgent from '@/hooks/useCheckMobileAgent';

const APP_STORE_URL = 'https://apps.apple.com/us/app/gobuid/id6468765101';
const GOOGLE_PLAY_URL =
  'https://play.google.com/store/apps/details?id=com.gobuid';

// TODO WIP research real Smart APP Banner in https://www.npmjs.com/package/smartbanner.js
function SmartAppBanner({t}, props) {
  const isMobile = useCheckMobileAgent();
  const [onClose, setOnClose] = useState(false);
  const [lock, setLock] = useState(false);
  const { handleSetIsOpenApp } = props;

  const handleCloseClick = () => {
    setOnClose(true);
    if (handleSetIsOpenApp) {
      handleSetIsOpenApp();
    }
  };

  const handleOpenApp = () => {
    setLock(true);

    const openApp = (urlScheme, redirectUrl) => {
      const userAgent = window.navigator.userAgent || window.navigator.vendor;
      let resultRedirectUrl = redirectUrl;
      if (isMobile) {
        if (/iPad|iPhone|iPod/.test(userAgent)) {
          resultRedirectUrl = APP_STORE_URL;
        }
        if (/android/i.test(userAgent)) {
          resultRedirectUrl = GOOGLE_PLAY_URL;
        }
      }
      const timeout = setTimeout(() => {
        window.location.href = resultRedirectUrl;
      }, 1000);

      window.location.href = urlScheme;

      // todo WIP 待觀察。 Q:為何寫這段? A: 實測 safari 出現對話框後，停止，但這段提早帶到 /download
      const timeout2 = setTimeout(() => {
        window.location.href = resultRedirectUrl;
        setLock(true);
      }, 1000);

      const handleBlur = () => {
        clearTimeout(timeout);
      };

      window.addEventListener('blur', handleBlur);

      return () => {
        window.removeEventListener('blur', handleBlur);
        clearTimeout(timeout);
        clearTimeout(timeout2);
        setLock(true);
      };
    };

    openApp('gobuidapp://', '/download');
  };

  return onClose ? null : (
    <div className="group top-0 z-[999] flex h-16 w-full items-center justify-center bg-[#001963] text-white lg:hidden">
      <div className="flex w-full items-center justify-between gap-[18px] px-4 py-3">
        <div className="flex items-center gap-2">
          <button
            type="button"
            className="group-checked:hidden"
            onClick={handleCloseClick}
          >
            <Image src={Close} alt="close App UI" className="w-full" />
          </button>
          <div className="h-10 w-10">
            <Image src={AppIcon} alt="Gobuid icon" className="w-full" />
          </div>
          <div className="flex flex-col justify-center">
            <p className="text-sm">GoBuid : {t.smartBannerTrackText}</p>
            <p className="text-[11px]">{t.smartBannerConstructionText}</p>
          </div>
        </div>
        <button
          type="button"
          onClick={handleOpenApp}
          disabled={lock}
          className="select-none rounded-[26px] bg-white px-4 py-[5px] text-center text-xs text-[#001963]"
        >
          {t.smartBannerButton}
        </button>
      </div>
    </div>
  );
}

export default SmartAppBanner;
