'use client';

import { usePathname } from 'next/navigation';

function useCheckHelpCenter() {
  const pathname = usePathname();
  const excludedPaths = [
    '/helpCenter',
    '/helpCenter/guide',
    '/helpCenter/learn',
    '/helpCenter/guide/:branch',
  ];

  const isExcluded =
    excludedPaths.includes(pathname) ||
    !!pathname.match(/^\/helpCenter\/guide\/[^/]+$/);

  return isExcluded;
}

export default useCheckHelpCenter;
