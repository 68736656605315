'use client';

import { usePathname } from 'next/navigation';

function useCheckCustomerStory() {
  const pathname = usePathname();
  const excludedPaths = ['/customer-story'];

  const isExcluded = excludedPaths.includes(pathname);

  return isExcluded;
}

export default useCheckCustomerStory;
