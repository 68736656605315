import React, { useEffect, useState } from 'react';
import Image from 'next/image';
import Link from 'next/link';
import ArrowDown from '@/assets/icons/features/arrow-down.svg?url';
import DemoNav from '@/components/navbar/DemoNav';

import gaEvent from '@/utils/ga';

function Nav({ navbarList, handleModalOpen, t }) {
  const [dropdown, setDropdown] = useState(false);
  const [navId, setNavId] = useState(0);

  const handleDropDownClick = (id) => {
    if (navId === id) {
      setDropdown(!dropdown);
    } else {
      setNavId(id);
      setDropdown(true);
    }
  };

  useEffect(() => {
    const handleOutSideClick = (e) => {
      const navElement = document.getElementById('navbar');
      const isClickInsideNav = navElement && navElement.contains(e.target);

      if (!isClickInsideNav) {
        setDropdown(false);
      }
    };

    window.addEventListener('click', handleOutSideClick);

    return () => {
      window.removeEventListener('click', handleOutSideClick);
    };
  }, []);

  const handleLinkClick = (name) => {
    setDropdown(!dropdown);
    gaEvent(`nav_click_${name}`, {
      event_category: 'Navigation',
      event_label: name,
      value: 0,
    });
  };

  return (
    <nav className="hidden h-full w-full lg:flex" id="navbar">
      {navbarList.map(({ id, title, linkList }) => (
        <div
          key={id}
          className="relative flex w-[156px] cursor-pointer items-center justify-center font-medium"
        >
          <button
            type="button"
            className={`${
              dropdown && navId === id ? '' : ''
            } flex items-center justify-center gap-1 rounded-[30px] px-1 py-2 lg:px-5`}
            onClick={() => handleDropDownClick(id)}
          >
            <div>{t[title]}</div>
            <div className="h-6 w-6 px-[5px] py-[7px]">
              <Image
                src={ArrowDown}
                alt="arrow down icon"
                className={`${
                  dropdown && navId === id ? 'rotate-180' : ''
                } w-full transition-all duration-200 ease-out`}
              />
            </div>
          </button>
          <ul
            className={`${
              dropdown && navId === id
                ? 'visible opacity-100'
                : 'invisible opacity-0'
            } absolute left-1/2 top-16 min-w-[170px] -translate-x-1/2 whitespace-nowrap rounded-2xl border-2 bg-white/85 p-2 backdrop-blur-sm transition-all duration-200 ease-in-out`}
            onClick={() => handleDropDownClick(id)}
          >
            {linkList.map(({ list, idList, href }) =>
              href.includes('http') ? (
                <a
                  key={idList}
                  href={href}
                  onClick={() => handleLinkClick(list)}
                  className="w-full font-medium"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <button
                    className="w-full rounded-lg p-2 text-left hover:shadow-[999px_999px_0px_0px_#0008210A_inset]"
                    type="button"
                  >
                    {t[list]}
                  </button>
                </a>
              ) : (
                <Link
                  key={idList}
                  href={href}
                  onClick={() => handleLinkClick(list)}
                  className="w-full font-medium"
                >
                  <li className="w-full rounded-lg p-2 hover:shadow-[999px_999px_0px_0px_#0008210A_inset]">
                    {t[list]}
                  </li>
                </Link>
              ),
            )}
            {title === 'Product' && (
              <DemoNav handleModalOpen={handleModalOpen} />
            )}
          </ul>
        </div>
      ))}
    </nav>
  );
}

export default Nav;
