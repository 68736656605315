import gaEvent from '@/utils/ga';

function DemoNav({ handleModalOpen }) {
  const handleLinkClick = () => {
    handleModalOpen();
    gaEvent(`nav_click_Demo Tour`, {
      event_category: 'Navigation',
      event_label: 'Demo Tour',
      value: 0,
    });
  };

  return (
    <button
      onClick={handleLinkClick}
      type="button"
      className="flex w-full  rounded-lg p-2 hover:bg-[#001963] hover:text-white"
    >
      Demo Tour
    </button>
  );
}

export default DemoNav;
