'use client';

import React, { useEffect, useState } from 'react';
import dynamic from 'next/dynamic';

import link from '@/data/link.json';
import { useAppDispatch, useAppSelector } from '@/lib/hooks';
import { fetchUserSelfThunk } from '@/lib/users/userAction';
import { fetchGetCompanyOverViewThunk } from '@/lib/account/accountAction';

import NavLogo from '@/components/navbar/NavLogo';
import Nav from '@/components/navbar/Nav';
import RightBtnArea from '@/components/navbar/RightBtnArea';
import { usePathname } from 'next/navigation';

const AuthShow = dynamic(() => import('@/components/navbar/AuthShow'));
const MobileNav = dynamic(() => import('@/components/popup/MobileNav'));
const VideoPop = dynamic(() => import('@/components/popup/VideoPop'));

const { navbarList } = link;

const Navbar = ({ t, lang }) => {
  const pathname = usePathname();
  const dispatch = useAppDispatch();
  const { profile } = useAppSelector((state) => state.user);

  const [showMenu, setShowMenu] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isActive, setIsActive] = useState(false);

  let bgColor;
  if (pathname === `/${lang}`) {
    bgColor = 'bg-primary-0';
  } else if (
    [
      `/${lang}/customer-story`,
      `/${lang}/termsofservice`,
      `/${lang}/privacy`,
    ].includes(pathname)
  ) {
    bgColor = 'bg-[#F6F4F2]';
  } else {
    bgColor = 'bg-white';
  }

  const handleModalOpen = () => {
    setIsModalOpen(true);
  };
  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  const handleToggleChange = () => {
    setIsActive(!isActive);
    setShowMenu(!showMenu);
  };

  const fetchUser = async () => {
    const res = await dispatch(fetchUserSelfThunk()).unwrap();
    const { groups } = res;

    const filterGroup = groups.filter((group) => [1, 2].includes(group.roleId));
    if (filterGroup) {
      const groupId = filterGroup[0].groupId || 0;
      if (groupId !== 0) {
        dispatch(fetchGetCompanyOverViewThunk(groupId));
      }
    }
  };

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token !== null) fetchUser();
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      if (showMenu) {
        setShowMenu(!showMenu);
        setIsActive(!isActive);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <header
      className={`${bgColor} ${showMenu ? 'overflow-hidden overscroll-contain bg-white' : ''} sticky left-0 top-0 z-50 flex w-full flex-col items-center justify-center p-4 transition-all duration-500 ease-in-out lg:top-0 lg:px-8  3xl:px-[320px]`}
    >
      <div className="relative mx-auto flex w-full max-w-[1800px] items-center justify-between">
        <div className="z-50 flex items-center lg:fixed lg:gap-4">
          <NavLogo />
          <Nav
            navbarList={navbarList}
            handleModalOpen={handleModalOpen}
            t={t}
          />
        </div>

        {isModalOpen && <VideoPop handleModalClose={handleModalClose} t={t} />}

        <div className="flex w-full items-center justify-end gap-3">
          <RightBtnArea t={t} />

          {!!profile.userId && <AuthShow profile={profile} />}
          <div
            className={`menu-icon ${isActive ? 'active' : ''} lg:hidden `}
            onClick={handleToggleChange}
          >
            <div className="line-1" />
            <div className="line-2" />
            <div className="line-3" />
          </div>
        </div>
      </div>
      <MobileNav
        navbarList={navbarList}
        showMenu={showMenu}
        handleModalOpen={handleModalOpen}
        handleToggleChange={handleToggleChange}
        t={t}
      />
    </header>
  );
};

export default Navbar;
