'use client';

import { useState, useEffect } from 'react';
import { useAppSelector } from '@/lib/hooks';
import useCheckMobileAgent from './useCheckMobileAgent';

function useGoRouter() {
  const isMobile = useCheckMobileAgent();
  const device = isMobile ? 'mobile' : 'desk';
  const { profile } = useAppSelector((state) => state.user);
  const { companyOverview } = useAppSelector((state) => state.account);
  const { planTypeName } = companyOverview.value;

  // 有兩個按鈕 文字和路由 會依據角色和付費狀態改變
  // 文字顯示
  const [loginArea, setLoginArea] = useState('login');
  const [startArea, setStartArea] = useState('getStarted');
  const [mobileLogin, setMobileLogin] = useState('login');
  const [mobileStartArea, setMobileStartArea] = useState('getStarted');

  // 路由變化
  const [goRouterPay, setGoRouterPay] = useState('/login');
  const [goRouterConsole, setGoRouterConsole] = useState('/signup');
  const [goRouterMobileLoginArea, setGoRouterMobileLoginArea] =
    useState('/login'); // 對應mobileLogin
  const [goRouterMobileStartArea, setGoRouterMobileStartArea] =
    useState('/signup'); // 對應mobileStartArea
  const [pricePageGetStartrdPageGo, setPricePageGetStartrdPageGo] =
    useState('/download');

  useEffect(() => {
    if (profile?.userId) {
      const { groups } = profile;

      const filterGroup = groups.filter((group) =>
        [1, 2].includes(group.roleId),
      );
      setLoginArea('');
      setMobileLogin('navLogout');
      setMobileStartArea('navDownloadApp');
      setGoRouterMobileLoginArea('');
      setGoRouterMobileStartArea('');

      // 未付費者 但有註冊
      if (planTypeName === 'Free') {
        if (device === 'mobile') {
          setStartArea('navUpgrade');
          setGoRouterPay(`/admin/${filterGroup[0].groupId}/chooseplan`);
          setGoRouterConsole(`/admin/${filterGroup[0].groupId}/chooseplan`);
        }
        if (device === 'desk') {
          setLoginArea('navUpgrade');
          setStartArea('navLaunchButton');
          setGoRouterPay(`/admin/${filterGroup[0].groupId}/chooseplan`);
          setGoRouterConsole(`/admin/${filterGroup[0].groupId}/members`);
          setPricePageGetStartrdPageGo(
            `/admin/${filterGroup[0].groupId}/members`,
          );
        }
      } else {
        // 付費者
        // web 都去admin console / mobile 都去download
        setLoginArea('');
        setStartArea('navLaunchButton');
        if (filterGroup.length > 0) {
          if (device === 'desk') {
            setGoRouterPay(`/admin/${filterGroup[0].groupId}/members`);
            setGoRouterConsole(`/admin/${filterGroup[0].groupId}/members`);
            setPricePageGetStartrdPageGo(
              `/admin/${filterGroup[0].groupId}/members`,
            );
          }

          if (device === 'mobile') {
            setGoRouterPay('/download');
            setGoRouterConsole('/download');
          }
        }
      }
    }
  }, [device, planTypeName, profile]);

  return {
    mobileLogin,
    mobileStartArea,
    goRouterPay,
    goRouterConsole,
    goRouterMobileLoginArea,
    goRouterMobileStartArea,
    pricePageGetStartrdPageGo,
    loginArea,
    startArea,
  };
}

export default useGoRouter;
