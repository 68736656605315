import { useEffect, useState } from 'react';
import Image from 'next/image';
import Link from 'next/link';
import GoBuid from '@/assets/icons/GoBuid-logo.svg?url';
import GoBuidAbbreviation from '@/assets/icons/GoBuid-abbreviation.svg?url';

function NavLogo() {
  // const [scrollY, setScrollY] = useState(0);

  // useEffect(() => {
  //   const handleScroll = () => {
  //     setScrollY(window.scrollY);
  //   };

  //   window.addEventListener('scroll', handleScroll);

  //   return () => {
  //     window.removeEventListener('scroll', handleScroll);
  //   };
  // }, []);
  return (
    <Link
      href="/"
      className=" relative flex h-full w-[104px] cursor-pointer items-center transition-all duration-500 ease-out md:px-2 lg:w-[188px]"
    >
      <Image src={GoBuid} alt="GoBuid logo" className="w-full" />
    </Link>
  );
}

export default NavLogo;
