'use client';

import { usePathname } from 'next/navigation';

function useCheckAuthPath() {
  const pathname = usePathname();
  const excludedPaths = ['/customer-story/createCustomerCase'];

  const isExcluded = excludedPaths.includes(pathname);

  return isExcluded;
}

export default useCheckAuthPath;
