'use client';

import Link from 'next/link';
import Image from 'next/image';
import GBGIcon from '@/assets/icons/GB-G-icon.svg';

import gaEvent from '@/utils/ga';
import { useEffect, useState } from 'react';

export default function ButtonTryFree({ t }) {
  const [isToken, setIsToken] = useState(false);

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token !== null) {
      setIsToken(!isToken);
    }
  }, []);

  return isToken ? null : (
    <div className=" fixed bottom-10 right-1/2 z-40 translate-x-1/2 transition-all duration-300 md:right-10 md:translate-x-0">
      <Link href="/signup">
        <button
          onClick={() => {
            const eventPage =
              window.location.pathname.split('/')[1].toLowerCase() || 'index';
            // document.title;

            gaEvent(`try_for_free_click_${eventPage}`, {
              event_category: 'Click',
              event_page: eventPage || 'homePage',
              value: 100,
            });
          }}
          type="button"
          className="duration-200 rounded-full transition-all ease-in-out flex items-center whitespace-nowrap border-[3px] border-white bg-secondary-600 px-5 py-2
            active:translate-y-[2px] active:translate-x-0 active:shadow-[0_0_0_0_#14E869] 
            hover:-translate-x-[2px] hover:-translate-y-1 hover:shadow-[2px_5px_0_0_#14E869] hover:bg-secondary-700"
        >
          <p className={`text-[16px] font-normal leading-[24px] lg:text-[20px] lg:leading-[28px] mr-1 text-white`}>
            {t.tryForFreeButton}
          </p>
          <GBGIcon />
        </button>
      </Link>
    </div>
  );
}
